import {
  get,
  uniq,
} from 'lodash';
import { normalize } from 'normalizr';
import { toast } from 'react-toastify';

import { getErrorMessage, captureException } from '../../../helpers/error';
import {
  sprayMixContainerSchema,
  sprayMixContainersSchema,
  notesSchema,
  upsertNormalizedEntities,
} from '../../../helpers/normalizers';
import {
  actions as sprayMixContainerRequestActions,
} from '../../request/sprayMixContainer/actions';
import { actions as noteRequestActions } from '../../request/note/actions';
import { sortAndLimitResults } from '../../view/actions';
import { actions as sprayMixContainerViewActions } from '../../view/sprayMixContainer/actions';
import { sprayMixContainerViewSelector } from '../../view/sprayMixContainer/selectors';
import {
  fetchFileFromAPI,
  fetchObjectsFromAPI,
  fetchNotesFromAPI,
  makeObjectActions,
  upsertObjectToAPI,
} from '../actions';
import { allConstants as constants } from './constants';
import { sprayMixContainerSelector } from './selectors';

const objectActions = makeObjectActions(constants);

/**
 * Load a list of objects
 * @param tableState
 * @param active
 */
export const fetchObjects = (tableState) => {
  return async dispatch => {
    dispatch(sprayMixContainerRequestActions.setLoadAllError(null));
    dispatch(sprayMixContainerRequestActions.setIsLoadingAll(true));
    try {
      const url = constants.FETCH_LIST_URL;
      const sprayMixContainers = await fetchObjectsFromAPI(url, "data");

      // Normalize the result and store the sprayMixContainers in redux
      const entities = get(
        normalize(sprayMixContainers, sprayMixContainersSchema),
        "entities",
        {}
      );
      dispatch(upsertNormalizedEntities(entities));

      // Do a sort/filter on the results and store it in the view store
      dispatch(
        sortAndLimitResults(sprayMixContainers, tableState, (ids, count) => {
          dispatch(sprayMixContainerViewActions.setList(ids, count));
        })
      );
      dispatch(sprayMixContainerRequestActions.setIsLoadedAll(true));
    } catch (e) {
      captureException(e);
      dispatch(sprayMixContainerRequestActions.setLoadAllError(getErrorMessage(e)));
    } finally {
      dispatch(sprayMixContainerRequestActions.setIsLoadingAll(false));
    }
  };
};

/**
 * Fetch the notes from the api and store their result in our reducer
 * @param id
 * @returns {Function}
 */
export const fetchNotes = id => {
  return async dispatch => {
    dispatch(noteRequestActions.setLoadAllError(null));
    dispatch(noteRequestActions.setIsLoadingAll(true));
    try {
      const notes = await dispatch(
        fetchNotesFromAPI(id, `${constants.FETCH_NOTE_LIST_URL}/${id}`)
      );

      dispatch(
        sprayMixContainerViewActions.setRelatedObjects(
          constants.RELATION_NOTES,
          id,
          notes.map(note => {
            return note.id;
          })
        )
      );
    } catch (e) {
      captureException(e);
      dispatch(noteRequestActions.setLoadAllError(getErrorMessage(e)));
    } finally {
      dispatch(noteRequestActions.setIsLoadingAll(false));
    }
  };
};

/**
 * Dump a spray mix container
 * @param id - The id of the spray mix container to dump
 * @returns {Function}
 */
export const dumpObject = id => {
  return async dispatch => {
    dispatch(sprayMixContainerRequestActions.setSaveError(null));
    dispatch(sprayMixContainerRequestActions.setIsSaving(true));
    try {
      await upsertObjectToAPI(
        constants.DUMP_OBJECT_URL.replace(":id", id),
        {}
      );
      dispatch(fetchObjects());
      toast.success("Spray Mix Container used successfully!");
    } catch (e) {
      captureException(e);
      dispatch(sprayMixContainerRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(sprayMixContainerRequestActions.setIsSaving(false));
    }
  };
};

/**
 * Fetch a single object from the api
 * @param id
 * @returns {Function}
 */
export const fetchObject = id => {
  return async dispatch => {
    dispatch(sprayMixContainerRequestActions.setLoadError(null));
    dispatch(sprayMixContainerRequestActions.setIsLoading(true));
    try {
      const result = await fetchObjectsFromAPI(
        `${constants.FETCH_URL.replace(':id', id)}`,
        "data"
      );

      // Normalize the result and store the other attributes in redux
      const entities = get(normalize(result, sprayMixContainerSchema), "entities", {});

      dispatch(upsertNormalizedEntities(entities));
      dispatch(sprayMixContainerRequestActions.setIsLoaded(id, true));
    } catch (e) {
      captureException(e);
      dispatch(sprayMixContainerRequestActions.setLoadError(getErrorMessage(e)));
    } finally {
      dispatch(sprayMixContainerRequestActions.setIsLoading(false));
    }
  };
};

/**
 * Spit out an excel version of the report
 * @returns {Function}
 */
export const fetchLabelsPdf = ids => {
  return async (dispatch, getState) => {
    dispatch(sprayMixContainerRequestActions.setLoadAllError(null));
    dispatch(sprayMixContainerRequestActions.setIsLoadingAll(true));
    try {
      await fetchFileFromAPI(
        constants.FETCH_LABELS_PDF_URL,
        `spray-mix-labels.pdf`,
        false,
        "application/pdf",
        true,
        { ids: ids }
      );
    } catch (e) {
      captureException(e);
      dispatch(sprayMixContainerRequestActions.setLoadAllError(getErrorMessage(e)));
    } finally {
      dispatch(sprayMixContainerRequestActions.setIsLoadingAll(false));
    }
  };
};

/**
 * Check if a spray mix container is valid before creating it
 * @param data
 * @param onSuccess
 * @returns {Function}
 */
export const validateObject = (data, onSuccess, onError) => {
  return async dispatch => {
    dispatch(sprayMixContainerRequestActions.setSaveError(null));
    dispatch(sprayMixContainerRequestActions.setIsSaving(true));
    try {
      const result = await upsertObjectToAPI(constants.VALIDATE_OBJECT_URL, data);

      if (onSuccess) {
        onSuccess(result);
      }
    } catch (e) {
      captureException(e);
      onError(get(e, 'response'));
    } finally {
      dispatch(sprayMixContainerRequestActions.setIsSaving(false));
    }
  };
};

/**
 * Save an object on the api
 * @param data
 * @param onSuccess
 * @returns {Function}
 */
export const upsertObject = (data, onSuccess, onError) => {
  return async dispatch => {
    dispatch(sprayMixContainerRequestActions.setSaveError(null));
    dispatch(sprayMixContainerRequestActions.setIsSaving(true));
    try {
      const result = await upsertObjectToAPI(constants.UPSERT_URL, data);
      const entities = get(
        normalize(result, sprayMixContainerSchema),
        "entities",
        {}
      );
      dispatch(upsertNormalizedEntities(entities));

      if (onSuccess) {
        onSuccess(result);
      }
    } catch (e) {
      captureException(e);
      onError(get(e, 'response'));
    } finally {
      dispatch(sprayMixContainerRequestActions.setIsSaving(false));
    }
  };
};

/**
 * Save a note on the api
 * @param parentId
 * @param data
 * @param onSuccess
 * @returns {Function}
 */
export const upsertNote = (parentId, data, onSuccess) => {
  return async (dispatch, getState) => {
    dispatch(noteRequestActions.setSaveError(null));
    dispatch(noteRequestActions.setIsSaving(true));
    try {
      data.kind = data.kind || "spraymixcontainer";

      const result = await upsertObjectToAPI(constants.UPSERT_NOTE_URL, data);
      const entities = get(normalize([result], notesSchema), "entities", {});
      dispatch(upsertNormalizedEntities(entities));

      const notes = sprayMixContainerViewSelector().getNoteIds(parentId)(getState());
      const updatedIds = uniq([...notes, result.id]);
      dispatch(
        sprayMixContainerViewActions.setRelatedObjects(
          constants.RELATION_NOTES,
          parentId,
          updatedIds
        )
      );

      if (onSuccess) {
        onSuccess(notes);
      }
    } catch (e) {
      captureException(e);
      dispatch(noteRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(noteRequestActions.setIsSaving(false));
    }
  };
};

/**
 * Filter, Sort, and Trim the results for the table
 * @param tableState
 * @returns {Function}
 */
export const updateSortFilterLimit = tableState => {
  return async (dispatch, getState) => {
    const sprayMixContainers = sprayMixContainerSelector().getDenormalizedObjects()(getState());
    // // Do a sort/filter on the results and store it in the view store
    dispatch(
      sortAndLimitResults(sprayMixContainers, tableState, (ids, count) => {
        dispatch(sprayMixContainerViewActions.setList(ids, count));
      })
    );
  };
};

export const actions = {
  ...objectActions,
  dumpObject,
  fetchObject,
  fetchObjects,
  fetchNotes,
  fetchLabelsPdf,
  updateSortFilterLimit,
  upsertObject,
  upsertNote,
  validateObject,
};
