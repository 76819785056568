
export const APP_BRAND_NAME = "Witherspoon Rose Culture";

export const DEFAULT_USER_PHOTO_URL = process.env.REACT_APP_DEFAULT_USER_PHOTO_URL

export const ALERT_ERROR = "error";
export const ALERT_WARNING = "warning";

export const EXPORT_TYPE_COPY = "copy";
export const EXPORT_TYPE_EXCEL = "excel";
export const EXPORT_TYPE_PDF = "pdf";
export const STATEMENT_LINE_TYPE_CREDIT = "credit";
export const STATEMENT_LINE_TYPE_EZ = "ez";

export const ADDRESS_TYPE_MAILING = "Mailing/Billing";
export const ADDRESS_TYPE_SERVICE = "Service";
export const ADDRESS_TYPE_OTHER = "Other";
export const ADDRESS_TYPE_MAILING_KEY = 2;
export const ADDRESS_TYPE_SERVICE_KEY = 4;

export const ADDRESS_BIT_PHYSICAL = 1;
export const ADDRESS_BIT_MAILING = 2;
export const ADDRESS_BIT_BILLING = 2;
export const ADDRESS_BIT_SERVICE = 4;
export const ADDRESS_BIT_LEGAL = 8;
export const ADDRESS_BIT_OTHER = 16;

export const ADDRESS_BIT_TYPE_MAP = {
  [ADDRESS_BIT_MAILING]: ADDRESS_TYPE_MAILING,
  [ADDRESS_BIT_SERVICE]: ADDRESS_TYPE_SERVICE,
  [ADDRESS_BIT_OTHER]: ADDRESS_TYPE_OTHER,
  [ADDRESS_BIT_MAILING + ADDRESS_BIT_SERVICE]: `${ADDRESS_TYPE_MAILING}/${ADDRESS_TYPE_SERVICE}`
}

export const ACTIVITY_TYPE_SYS = "sys";

export const INVENTORY_SALES_REPORT_TYPE_ALL = "web";
export const INVENTORY_SALES_REPORT_TYPE_BILL_TO = "webbillto";
export const INVENTORY_SALES_REPORT_PDF_ALL = "pdf";
export const INVENTORY_SALES_REPORT_PDF_BILL_TO = "pdfbillto";

export const OPTIONS_APPOINTMENT_TYPE_NEW_BED = "New Bed";
export const OPTIONS_APPOINTMENT_TYPE_REWORK = "Rework";
export const OPTIONS_APPOINTMENT_TYPE_NEW_SERVICE = "New Service";
export const OPTIONS_APPOINTMENT_TYPE = [
  OPTIONS_APPOINTMENT_TYPE_NEW_BED,
  OPTIONS_APPOINTMENT_TYPE_NEW_SERVICE,
  OPTIONS_APPOINTMENT_TYPE_REWORK,
  "Consultation",
];

export const OPTIONS_APPOINTMENT_STATUS = [
  {
    id: 1,
    name: "Open",
  },
  {
    id: 2,
    name: "Declined",
  },
  {
    id: 3,
    name: "Closed",
  },
];

export const OPTIONS_ACTIVATE_CANCEL = [
  "Competitor",
  "Financial",
  "Health/Death",
  "Lost Interest",
  "Moving/sold house",
  "Non-payment",
  "Unknown",
];

export const OPTION_ACTIVATE_CANCEL_FINANCIAL = "Financial";

export const OPTIONS_HOW_LEARNED = [
  "Word of Mouth",
  "Previous Customer",
  "Garden Shows",
  "Garden Club Talk",
  "Saw Our Truck",
  "Saw Our Sign",
  "Landscaper/Lawn/Tree Svc",
  "ARS",
  "ARS Free Rose",
  "Internet Search",
  "Social Media",
  "Web Order",
  "NC State Fair",
  "Email Advertising",
  "Direct Mail Advertising",
  "Newspaper",
  "Saw Catalog",
  "Magazine",
  "Radio",
  "TV",
  "Express Location",
  "Cold Call",
  "Auction/Charity Event",
  "DPAC",
  "Other",
];

export const OPTIONS_PAYMENT_TYPE = [
  "check",
  "cash",
  "credit card",
  "authorize.net",
  "square",
  "amazon",
  "paypal",
  "ACH",
  "gift card",
  "coupon",
  "adjustment",
];

export const OPTIONS_TAX_EXEMPT_TYPE = [
  "wholesale",
  "government",
  "marketplace",
  "other",
];

export const WO_FILTER_PLACEHOLDER = "Select Filter";
export const WO_FILTER_ALL_RELATED_TO_ME = "All Related to Me";
export const WO_FILTER_ONLY_ME = "Only Assigned to Me";
export const WO_FILTER_MY_GROUP = "My Group";
export const WO_FILTER_UNASSIGNED = "Not Assigned";
export const WO_FILTER_ALL = "All Work Orders";
export const WO_FILTER_COMPLETED = "Completed";

export const INVOICE_FILTER_PLACEHOLDER = "Select Filter";
export const INVOICE_TYPE_RETAIL = "retail";
export const INVOICE_TYPE_PHONE = "phone";
export const INVOICE_TYPE_POS = "POS";
export const INVOICE_TYPE_PICKUP = "Pickup";
export const INVOICE_TYPE_DELIVERY = "Delivery";
export const INVOICE_TYPE_SHIPMENT = "Shipment";
export const INVOICE_TYPE_ECOMM = "Ecomm";
export const INVOICE_TYPE_SERVICE = "Service";
export const INVOICE_TYPE_BED = "Bed";
export const INVOICE_TYPE_DIGITAL = "Digital";

export const SERVICE_OPTION_PLAN = "plan";

export const ESTIMATE_STATUS_DRAFT = "draft";
export const ESTIMATE_STATUS_CANCELLED = "cancelled";

export const BED_PACKET_STATUS_DRAFT = "draft";
export const BED_PACKET_STATUS_OPEN = "open";
export const BED_PACKET_STATUS_IN_PROCESS = "in_process";
export const BED_PACKET_STATUS_COMPLETED = "completed";
export const BED_PACKET_STATUS_APPROVED = "approved_arsm";
export const BED_PACKET_STATUS_DECLINED = "declined_arsm";
export const BED_PACKET_STATUS_READY_TO_BILL = "ready_to_bill";
export const BED_PACKET_STATUS_BILLED = "billed";
export const BED_PACKET_STATUS_CANCELLED = "cancelled";

export const INVOICE_STATUS_DRAFT = "draft";
export const INVOICE_STATUS_SAVED = "saved";
export const INVOICE_STATUS_LOCKED = "locked";
export const INVOICE_STATUS_DELETED = "deleted";
export const INVOICE_STATUS_PROCESSING = "processing";
export const INVOICE_STATUS_DONE = "done";
export const INVOICE_STATUS_PAID = "paid";
export const INVOICE_STATUS_PAID_DONE = "paid_done";
export const INVOICE_STATUS_CLOSED = "closed";
export const INVOICE_STATUS_REVERSED = "reversed";

export const BED_INVOICE_PLACEHOLDER_SKUS = ["Materials", "Labor", "Irrigation", "Plants", "Other", "Monthly EZ Fee service"];

export const INVOICE_TYPES = [
  INVOICE_TYPE_PICKUP,
  INVOICE_TYPE_DELIVERY,
  INVOICE_TYPE_SHIPMENT,
  INVOICE_TYPE_SERVICE,
  INVOICE_TYPE_BED,
  INVOICE_TYPE_DIGITAL,
];
export const INVOICE_TYPES_PHONE = [
  INVOICE_TYPE_PICKUP,
  INVOICE_TYPE_DELIVERY,
  INVOICE_TYPE_SHIPMENT,
];

export const OPTIONS_WORK_ORDER_FILTER = [
  WO_FILTER_ONLY_ME,
  WO_FILTER_ALL_RELATED_TO_ME,
  WO_FILTER_MY_GROUP,
  WO_FILTER_UNASSIGNED,
  WO_FILTER_COMPLETED,
  WO_FILTER_ALL,
];

export const WO_FILTER_FETCH_MAP = {
  [WO_FILTER_ONLY_ME]: "6/:userId",
  [WO_FILTER_ALL_RELATED_TO_ME]: "9/:userId",
  [WO_FILTER_MY_GROUP]: "4/:userId",
  [WO_FILTER_UNASSIGNED]: "3",
  [WO_FILTER_ALL]: "0",
  [WO_FILTER_COMPLETED]: "2",
};

export const WO_STATUS_OPEN = "open";
export const WO_STATUS_CLOSED = "closed";
export const WO_STATUS_DELETED = "deleted";
export const WO_STATUS_REVERSED = "reversed";
export const WO_STATUS_CANCELLED = "cancelled";

export const PRODUCT_CLASS_ROSE = 61;
export const PRODUCT_CLASS_CHEMICALS = 4;

export const PO_FILTER_ALL = "All";
export const PO_FILTER_CURRENT_WEEK = "Current Week";
export const PO_FILTER_LAST_WEEK = "Last Week";
export const PO_FILTER_CURRENT_MONTH = "Current Month";
export const PO_FILTER_LAST_MONTH = "Last Month";
export const PO_FILTER_CURRENT_QUARTER = "Current Quarter";
export const PO_FILTER_LAST_QUARTER = "Last Quarter";
export const PO_FILTER_CURRENT_YEAR = "Current Year";
export const PO_FILTER_LAST_YEAR = "Last Year";
export const PO_FILTER_CUSTOM = "Custom";

export const TRANSFER_STATUS_DRAFT = "draft";
export const TRANSFER_STATUS_PENDING = "pending";
export const TRANSFER_STATUS_RESERVED = "reserved";
export const TRANSFER_STATUS_APPROVED = "approved";
export const TRANSFER_STATUS_DELETED = "deleted";
export const TRANSFER_STATUS_REVERSED = "reversed";
export const TRANSFER_STATUS_RESERVED_APPROVED = "reservedapproved";
export const TRANSFER_STATUS_SENT = "sent";
export const TRANSFER_STATUS_BEGIN_RECEIVING = "receiving in progress";
export const TRANSFER_STATUS_DONE_RECEIVING = "done";

export const TRANSFER_GOOD_STATUS_DONE = "done";
export const TRANSFER_GOOD_STATUS_SENT = "sent";
export const TRANSFER_GOOD_STATUS_CANCELLED = "cancelled";
export const TRANSFER_RECEIVE_LINE_NOT = "not";
export const TRANSFER_RECEIVE_LINE_SALE = "sale";

export const PAYMENT_TYPE_CHECK = "check";
export const PAYMENT_TYPE_CASH = "cash";
export const PAYMENT_TYPE_CREDIT_CARD = "credit card";
export const PAYMENT_TYPE_AUTHORIZE = "authorize.net";
export const PAYMENT_TYPE_SQUARE = "square";
export const PAYMENT_TYPE_AMAZON = "amazon";
export const PAYMENT_TYPE_PAYPAL = "paypal";
export const PAYMENT_TYPE_ACH = "ach";
export const PAYMENT_TYPE_GIFT_CARD = "gift card";
export const PAYMENT_TYPE_COUPON = "coupon";
export const PAYMENT_TYPE_ADJUSTMENT = "adjustment";

export const TAX_RATE = 6.75;

export const BUSH_COUNT_ESTIMATE_MULTIPLIER = 1.5;
export const RANGE_ESTIMATE_MULTIPLIER_LOW = 0.9;
export const RANGE_ESTIMATE_MULTIPLIER_HIGH = 1.1;

export const BEDFLOW_ACCEPTED_SERVICE = "acceptedservice";
export const BEDFLOW_DECLINED_SERVICE = "declinedservice";
export const BEDFLOW_ACCEPTED_BED = "acceptedbed";
export const BEDFLOW_DECLINED_BED = "declinedbed";
export const BEDFLOW_SENT = "sent";
export const BEDFLOW_DRAFT = "draft";
export const BEDFLOW_CANCELLED = "cancelled";

export const WORK_ORDER_LINE_ITEM_PULL = "pull";
export const WORK_ORDER_LINE_ITEM_PICKUP = "pickup";
export const WORK_ORDER_LINE_ITEM_DELIVER = "deliver";
export const WORK_ORDER_LINE_ITEM_INSTALL = "install";
export const WORK_ORDER_LINE_ITEM_SHIP = "ship";

export const WORK_ORDER_LINE_ITEM_INSTALLED = "installed";
export const WORK_ORDER_LINE_ITEM_PICKEDUP = "pickedup";
export const WORK_ORDER_LINE_ITEM_PULLED = "pulled";
export const WORK_ORDER_LINE_ITEM_RESERVED = "reserved";
export const WORK_ORDER_LINE_ITEM_REJECTED = "rejected";
export const WORK_ORDER_LINE_ITEM_SHIPPED = "shipped";
export const WORK_ORDER_LINE_ITEM_DELIVERED = "delivered";

export const ROSE_COLORS = {
  m: "Multi",
  o: "Orange",
  p: "Pink",
  r: "Red",
  w: "White",
  y: "Yellow",
  a: "Apricot Coral",
  l: "Lavender",
};

export const ROSE_SIZES = {
  "1": "18",
  "2": "24",
  "3": "36",
  "4": "48",
  "5": "60",
};

export const UOM_TYPES = [
  {
    id: 1,
    name: "in",
    type: "length",
  },
  {
    id: 2,
    name: "ft",
    type: "length",
  },
  {
    id: 3,
    name: "lb",
    type: "weight",
  },
  {
    id: 4,
    name: "oz",
    type: "weight",
  },
  {
    id: 5,
    name: "g",
    type: "weight",
  },
  {
    id: 6,
    name: "mg",
    type: "weight",
  },
  {
    id: 7,
    name: "kg",
    type: "weight",
  },
  {
    id: 8,
    name: "floz",
    type: "volume",
  },
  {
    id: 9,
    name: "gallon",
    type: "volume",
  },
  {
    id: 10,
    name: "quart",
    type: "volume",
  },
  {
    id: 11,
    name: "mm",
    type: "length",
  },
  {
    id: 12,
    name: "ml",
    type: "volume",
  },
];

export const STATEMENT_LINE_TYPES_DEBIT = [
  "prevbal",
  "nonsvc charge",
  "svc charge",
  "ez",
];
export const STATEMENT_LINE_TYPES_CREDIT = [
  "refund",
  "svc credit",
  "nonsvc credit",
];

export const STATEMENT_LINE_TYPES = [
  { value: "misc", text: "Miscellaneous", disabled: true },
  { value: "credit", text: "Credit", disabled: true },
  // { value: "refund", text: "Refund" },
  { value: "svcadj", text: "Service Adjustment", disabled: true },
  { value: "ez", text: "EZ" },
  { value: "svc charge", text: "Service Charge" },
  // { value: "svc credit", text: "Service Credit" },
  { value: "nonsvc charge", text: "Non-Service Charge" },
  // { value: "nonsvc credit", text: "Non-Service Credit" },
  { value: "prevbal", text: "Previous Balance" },
];

export const SALES_GOAL_TYPES = [
  { value: "proposals_signed", text: "Signed Proposals"},
  { value: "adds_cw", text: "Adds-CW" },
  { value: "billable_extra", text: "Billable Extra" },
];

export const ROSE_TYPES = [
  { code: "c", type: "Climbing Roses", id: 1, sort: 6 },
  {
    code: "d",
    type: "David Austin / English Roses",
    id: 1,
    sort: 5,
  },
  {
    code: "f",
    type: "Floribunda Roses",
    id: 1,
    sort: 3,
  },
  {
    code: "g",
    type: "Grandiflora Roses",
    id: 1,
    sort: 2,
  },
  {
    code: "h",
    type: "Hybrid Tea & Grandiflora Roses",
    id: 1,
    sort: 1,
  },
  {
    code: "m",
    type: "Miniature Roses",
    id: 1,
    sort: 8,
  },
  {
    code: "o",
    type: "Ol' Country Garden Roses",
    id: 1,
    sort: 4,
  },
  {
    code: "a",
    type: "Available Only In Pots",
    id: 1,
    sort: 9,
  },
  {
    code: "s",
    type: "Own Root Shrub",
    id: 1,
    sort: 11,
  },
  {
    code: "u",
    type: "True Old Fashions-O/R",
    id: 1,
    sort: 10,
  },
  {
    code: "p",
    type: "Polyantha & Ground Covers",
    id: 1,
    sort: 12,
  },
  { code: "t", type: "Tree Roses", id: 1, sort: 7 },
];

export const OPTIONS_PURCHASE_ORDER_FILTER = [
  PO_FILTER_ALL,
  PO_FILTER_CURRENT_WEEK,
  PO_FILTER_LAST_WEEK,
  PO_FILTER_CURRENT_MONTH,
  PO_FILTER_CURRENT_QUARTER,
  PO_FILTER_LAST_QUARTER,
  PO_FILTER_CURRENT_YEAR,
  PO_FILTER_LAST_YEAR,
  PO_FILTER_CUSTOM,
];

export const PO_FILTER_FETCH_MAP = {
  [PO_FILTER_CURRENT_WEEK]: "week",
  [PO_FILTER_LAST_WEEK]: "lastweek",
  [PO_FILTER_CURRENT_MONTH]: "month",
  [PO_FILTER_CURRENT_QUARTER]: "quarter",
  [PO_FILTER_LAST_QUARTER]: "lastquarter",
  [PO_FILTER_CURRENT_YEAR]: "year",
  [PO_FILTER_LAST_YEAR]: "lastyear",
};

export const OPTIONS_INVOICE_STATUS = [
  { id: "open", name: "Open" },
  { id: "done", name: "Done" },
  { id: "draft", name: "Draft" },
  { id: "locked", name: "Locked" },
  { id: "paid", name: "Paid" },
  { id: "paid_done", name: "Paid Done" },
  { id: "processing", name: "Processing" },
  { id: "reversed", name: "Reversed" },
  { id: "saved", name: "Saved" },
];

export const OPTIONS_INVOICE_TYPE = [
  { id: "bed", name: "Bed" },
  { id: "spot", name: "Spot" },
  { id: "pos", name: "POS" },
  { id: "delivery", name: "Deliver" },
  { id: "pickup", name: "Pickup" },
  { id: "shipment", name: "Shipment" },
  { id: "other", name: "Other" },
  { id: "service", name: "Service" },
  { id: "special", name: "Special" },
  { id: "ecomm", name: "Ecomm" },
];

export const OPTIONS_WORK_ORDER_STATUS = [
  { id: "open", name: "Open" },
  { id: "closed", name: "Closed" },
  { id: "cancelled", name: "Cancelled" },
  { id: "deleted", name: "Deleted" },
];

export const OPTIONS_WORK_ORDER_TYPE = [
  { id: 3, name: "Spot Plant", key: "spotplant" },
  { id: 5, name: "Service", key: "newservicecall" },
  { id: 6, name: "Info", key: "infocall" },
  { id: 1, name: "Sales", key: "estimate" },
];

export const OPTIONS_WORK_ORDER_TYPE_ALL = [
  ...OPTIONS_WORK_ORDER_TYPE,
  { id: 12, name: "Bed", key: "bedaction" },
  { id: 11, name: "Pickup", key: "pickupaction" },
  { id: 10, name: "Delivery", key: "deliveryaction" },
  { id: 9, name: "Shipment", key: "shipmentaction" },
];

export const OPTIONS_WORK_ORDER_PRIORITY = [
  { id: 0, name: "Normal" },
  { id: 1, name: "Urgent" },
];

export const OPTIONS_PROCESSING = [
  { id: "automatic", name: "Automatic" },
  { id: "manual", name: "Manual" },
  { id: "email", name: "Email" },
  { id: "none", name: "None" },
  { id: "discontinued", name: "Discontinued" },
];

export const OPTIONS_PROBLEM = [
  "No Access",
  "Animals",
  "Locked Gate",
  "Heat",
  "Other",
  "Weather",
];

export const TARGET_GALLONS = [1, 2, 4, 5, 10, 25, 50, 75, 100, 125, 150, 175, 200];

export const PAID_ROSES_SECTIONS = [
  { key: "paidThisMonthAndFinished", label: "Paid and Delivered this Month" },
  {
    key: "paidEarlierMonthAndFinishedThisMonth",
    label: "Paid Earlier and Delivered this Month",
  },
  {
    key: "paidThisMonthNotFinished",
    label: "Paid This Month - not Delivered Yet",
  },
  { key: "freeThisMonthAndFinished", label: "Free This Month - and Delivered" },
  {
    key: "bedPaidThisMonthAndFinished",
    label: "Bed Install This Month - and Delivered",
  },
  { key: "spotplantRoses", label: "Spot Plant This Month" },
];

export const ROW_CLASSNAME_ERROR = "error";
export const ROW_CLASSNAME_DISABLED = "disabled";
export const ROW_CLASSNAME_WARNING = "warning";
export const ROW_CLASSNAME_POSITIVE = "positive";

export const AUTO_PAY_TYPE = {
  ccAuto: "ccauto",
  achAuto: "achauto",
};

export const OTHER_PAY_TYPE = {
  manual: "manual",
  ccManual: "ccmanual",
};

export const ALL_PAY_TYPE = {
  ...AUTO_PAY_TYPE,
  ...OTHER_PAY_TYPE,
};

export const PORTAL_BILLING_TYPES = {
  ccauto: "Credit Card - Automatic",
  manual: "Manual",
  achauto: "ACH - Automatic"
};

export const DEFAULT_SERVICE_PAYMENT_NOTE = "Payment on Service Account";

export const INVENTORY_LOT_LOADING = "loading";
export const NEW_INVENTORY_LOT_LABEL = "No existing lot - create a new lot";

export const BUCKET_ONHAND = "onhand";
export const BUCKET_RECEIVED = "received";
export const BUCKET_RESERVED = "reserved";
export const BUCKET_DAMAGED = "damaged";
export const BUCKET_FORSALE = "forsale";
export const BUCKET_PRESALE = "presale";
export const BUCKET_SHRINKAGE = "shrinkage";
export const BUCKET_CONVERTED = "converted";
export const BUCKET_SUPPLIES = "supplies";
export const BUCKET_INTUSE = "intuse";
export const BUCKET_ADJUST_UP = "adjusted_up";
export const BUCKET_ADJUST_DOWN = "adjusted_down";
export const BUCKET_SOLD = "sold";
export const BUCKET_USED = "used";
export const BUCKET_TRANSFERRED = "transferred";
export const BUCKET_TRANSFERREDIN = "transferredin";

export const BUCKET_LIST = [
  BUCKET_ONHAND,
  BUCKET_RECEIVED,
  BUCKET_RESERVED,
  BUCKET_DAMAGED,
  BUCKET_FORSALE,
  BUCKET_PRESALE,
  BUCKET_SHRINKAGE,
  BUCKET_CONVERTED,
  BUCKET_SUPPLIES,
  BUCKET_INTUSE,
  BUCKET_ADJUST_UP,
  BUCKET_ADJUST_DOWN,
  BUCKET_SOLD,
  BUCKET_USED,
  BUCKET_TRANSFERRED,
  BUCKET_TRANSFERREDIN,
];

export const BUCKET_LIST_FULL = {
  [BUCKET_ONHAND]: "On hand",
  [BUCKET_FORSALE]: "For sale",
  [BUCKET_SOLD]: "Sold",
  [BUCKET_RECEIVED]: "Received",
  [BUCKET_RESERVED]: "Reserved",
  [BUCKET_DAMAGED]: "Scratch/dent",
  [BUCKET_PRESALE]: "Pre sale",
  [BUCKET_SHRINKAGE]: "Shrinkage",
  [BUCKET_CONVERTED]: "Converted",
  [BUCKET_SUPPLIES]: "Supplies",
  [BUCKET_ADJUST_UP]: "Adj UP",
  [BUCKET_ADJUST_DOWN]: "Adj DN",
  [BUCKET_USED]: "Used",
  [BUCKET_INTUSE]: "Internal Use",
  [BUCKET_TRANSFERRED]: "Xfer out",
  [BUCKET_TRANSFERREDIN]: "Xfer in",
};

export const PERMISSION_BED_INVOICE_LOCATION_OVERRIDE = "admin.invoices.bed.location_override";

export const MESSAGE_INVALID_PIN_LENGTH = "4-digit PIN is required.";
export const MESSAGE_INVALID_PIN = "Invalid PIN";
export const MESSAGE_REASON_REQUIRED = "Reason is required.";
export const MESSAGE_DISCOUNT_REQUIRED = "Discount is required.";
export const MESSAGE_PRICE_REQUIRED = "New price is required.";
export const MESSAGE_INSUFFICIENT_PERMISSIONS =
  "Sorry, you do not have sufficient permissions.";

export const MESSAGE_NO_CUSTOMER_PORTAL_ACCOUNT =
  "There is no active customer portal account associated with this customer record, but we found a registered user email that matches the email address on this customer record.";
export const MESSAGE_GENERATE_REGISTRATION_LINK =
  "This customer does not have a registered user account. Use the button below to generate a registration link for this customer record. This link will be sent in an email message to the customer's email address. The link will permit the customer to create a password for their new customer portal login.";
export const MESSAGE_NO_LINES = "No lines match this request.";
